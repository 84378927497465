<template>
  <div class="page">
    <div class="column">
      <img src="./assets/logo.webp" class="logo">
      <template v-if="goodParams">
        <button class="btn letter-spacing" @click="disconnectWallet" v-if="accountAddress">Disconnect</button>
        <button class="btn letter-spacing" @click="connectWallet" v-else>Connect</button>

        <template v-if="accountAddress">
          <h4>connected to</h4>
          <h2>{{ accountAddress }}</h2>

          <h4 v-if="accountAddress !== addrFromTx">
            Transaction address: {{ addrFromTx }}
          </h4>
          <h4 v-if="accountAddress !== authAddr">
            Auth address: {{ authAddr }}
          </h4>

          <div class="btn-wrapper" v-if="accountAddress === addrFromTx">

            <img src="./assets/arrow_right.webp" class="arrow">
            <button class="btn" v-if="!processingResult" @click="processTransactions">
              Process transactions
            </button>
            <img src="./assets/arrow_right.webp" class="arrow mirrored">
          </div>
          <template v-else>
            <h2 style="color: red;">Transactions Address and connected address mismatch.</h2>
            <h2>Disconnect and reconnect to pera using only one address.</h2>
            <h3>The selected address must coincide with the transaction address</h3>
          </template>
        </template>
      </template>
      <template v-else>
        <h2 style="color: red;">Bad or None encoded transactions.</h2>
        <h3>Please request a new link</h3>
      </template>

      <div v-if="processingResult" class="card">
        <h1 v-for="line in processingResult.split('\n')" :key="line">{{ line }}</h1>
      </div>
      <h6 class="note">
        By using our website, you agree to the use of cookies and local storage for site
        functionality only. Rest assured, we do not store private data or share information
        with undisclosed third parties. Your privacy and security are our top priority.
        If you have any concerns, please contact us. More information at <a
          href="https://www.algocean.org">algocean.org</a>.
      </h6>
    </div>
    <div class="column">
      <div id="animation-wrapper">
        <img id="animation-1" src="./assets/animation_1.gif" class="animation">
        <img id="animation-2" src="./assets/animation_2.gif" class="animation">
      </div>
    </div>
  </div>

  <!-- <div class="log-container">
    <button @click="toggleLog">{{ displayLog ? "Hide Log" : "Display Log" }}</button>
    <div v-if="displayLog" class="log-section">
      <p v-for="(item, index) in log" :key="index">{{ item }}</p>
    </div>
  </div> -->
</template>

<script>
import { PeraWalletConnect } from "@perawallet/connect";
import { decodeSignedTx, decodeUnsignedTx } from '@/txs.js'
import algosdk from "algosdk";


const peraWallet = new PeraWalletConnect();
const algodClient = new algosdk.Algodv2('', 'https://xna-mainnet-api.algonode.cloud', 443);

export default {
  name: "App",
  data() {
    return {
      accountAddress: "",
      payment: null,
      feepay: null,
      optin: null,
      originalTransfer: null,
      transfer: null,
      goodParams: true,
      processingResult: null,
      addrFromTx: null,
      signedTxns: null,
      authAddr: null,
      log: [],
      displayLog: false,
    };
  },
  async mounted() {
    this.logMsg('mounted app')
    let params = new URLSearchParams(window.location.search)
    try {
      this.payment = decodeUnsignedTx(params.get('payment'))
      this.addrFromTx = algosdk.encodeAddress(this.payment.from.publicKey)
      this.feepay = decodeUnsignedTx(params.get('feepay'))
      this.optin = decodeUnsignedTx(params.get('optin'))
      this.originalTransfer = params.get('transfer');
      this.transfer = decodeSignedTx(params.get('transfer'));

      this.accData = await algodClient.accountInformation(this.addrFromTx).do();
      this.authAddr = 'auth-addr' in this.accData
        ? this.accData['auth-addr']
        : this.addrFromTx

      this.logMsg('Params loaded')
    } catch (error) {
      console.log('Bad tx params', error)
      this.goodParams = false
      this.logMsg('critical error failing to load params')
      return
    }
    peraWallet
      .reconnectSession()
      .then((accounts) => {
        peraWallet.connector?.on("disconnect", this.disconnectWallet);

        if (accounts.length) {
          this.accountAddress = accounts[0];
        }
        this.logMsg('pera wallet reconnected')
      })
      .catch((error) => {
        if (error?.data?.type !== "CONNECT_MODAL_CLOSED") {
          console.log(error);
          //alert(error)
          this.logMsg('Failed to reconnect pera wallet')
        }
      });
  },
  methods: {
    logMsg(msg) {
      this.log.push(msg)
    },
    connectWallet() {
      peraWallet
        .connect()
        .then((accounts) => {
          peraWallet.connector.on("disconnect", this.disconnectWallet);

          this.accountAddress = accounts[0];

          this.logMsg('pera wallet connected')
        })
        .catch((e) => {
          console.log(e)
          this.logMsg('failed to connect pera wallet')
        });
    },
    disconnectWallet() {
      peraWallet.disconnect().then(() => (this.accountAddress = null));
    },
    async processTransactions() {
      this.logMsg('Processing transactions...')
      this.logMsg('requesting pera to sing txs...')
      try {
        let signedTxns = await peraWallet.signTransaction([[
          { txn: this.payment, signers: [this.accountAddress] },
          { txn: this.feepay, signers: [this.accountAddress] },
          { txn: this.optin, signers: [this.accountAddress] },
          { txn: this.transfer.txn, signers: [] },
        ]]);
        this.processingResult = 'Received transactions from pera. Submitting to the blockchain...'
        this.logMsg('Got transactions from pera')
        this.logMsg(`txs ${signedTxns.length}: ${signedTxns}`)

        console.log(signedTxns)

        if (signedTxns.length === 3) {
          signedTxns.push(this.originalTransfer);
          this.logMsg('adding original transfer to txs')
        }

        if (signedTxns.length !== 4) {
          this.logMsg('Critical error: bad number of txs')
          throw new Error("Pera wallet returned an incomplete set of transactions")
        }

        signedTxns = signedTxns.filter((txn) => txn).map((txn) => new Uint8Array(Buffer.from(txn, 'base64')));
        this.signedTxns = signedTxns

        console.log('Received signed transactions', signedTxns)
        this.processResult = 'Submitting transactions...'
        this.logMsg('Submitting transactions to algorand network')

        let txId;
        try {
          let res = await algodClient.sendRawTransaction(signedTxns).do();
          txId = res.txId
        } catch (err) {
          console.dir(err)
          this.logMsg(`Critical error: failed to send transactions to the blockchain. error: ${err}`)
          throw err
        }

        this.logMsg(`Received txid: ${txId}, waiting confirmation`)
        console.log("Transaction : " + txId);
        // alert('Transactions submitted to the network. Id:', txId)

        // Wait for transaction to be confirmed
        let confirmedTxn;
        try {
          confirmedTxn = await algosdk.waitForConfirmation(algodClient, txId, 4);
        } catch (err) {
          let msg = "Error waiting for the tx confirmation but the transactions were sent please review on algoexplorer"
          this.logMsg(msg)
          throw new Error(msg)
        }
        //Get the completed Transaction
        console.log("Transaction " + txId + " confirmed in round " + confirmedTxn["confirmed-round"]);
        this.logMsg("Transaction " + txId + " confirmed in round " + confirmedTxn["confirmed-round"]);
        // alert('Transactions confirmed')
        this.processingResult = 'Transaction confirmed!'
      } catch (err) {
        this.processingResult = 'An error ocurred:\n' + err.response.body.message + '\nTry refreshing the page or requesting a new link from the bot.'
      }
    },
    toggleLog() {
      this.displayLog = !this.displayLog
    }
  },
};
</script>

<style>
@font-face {
  font-family: 'Big Shoulders Display';
  src: url(./assets/Big_Shoulders_Display/BigShouldersDisplay-VariableFont_wght.ttf) format('truetype');
}

@font-face {
  font-family: 'Agrandir';
  src: url(./assets/Agrandir.woff2) format('woff2');
}

html,
body {
  margin: 0;
  display: flex;
  height: 100vh;
  flex-direction: column;
  font-family: 'Big Shoulders Display';
  color: white;
  text-align: center;
  word-break: break-all;
  background: url(./assets/background.jpg);
  background-size: cover;
  background-attachment: fixed;
}

h4,
h6 {
  font-family: 'Agrandir';
}

#app {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(44, 30, 74, 0.8);
}

.page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

@media (max-width: 1100px) {
  .page {
    flex-direction: column;
  }
}

.column {
  display: flex;
  flex: 1 0 100px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding: min(6vw, 6vh);
}

.logo {
  width: min(50vw, 30vh);
  margin-bottom: 5vh;
}

#animation-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 65vh;
  margin-top: 5vh;
}

#animation-1 {
  transform: rotate(123deg);
  height: min(38vh, 38vw);
}

#animation-2 {
  top: max(-14vh, -14vw);
  position: relative;
  height: min(40vh, 40vw);
}

.btn-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn {
  border: unset;
  font-family: 'Big Shoulders Display';
  color: white;
  font-size: max(min(4vh, 4vw), 24px);
  padding: max(min(0.5vh, 0.5vw), 3px) max(min(4vh, 4vw), 24px);
  text-shadow: 0px 0px 10px white;
  background: linear-gradient(90deg, rgb(93, 224, 230) 0%, rgb(0, 74, 173) 100%);
  text-transform: uppercase;
  font-weight: bold;
  box-shadow: max(min(1vh, 1vw), 6px) max(min(1vh, 1vw), 6px) 0px 0px rgba(0, 0, 0, 0.4);
  cursor: pointer;
  word-break: normal;
}
.btn:hover {
  top: 0.5vh;
  position: relative;
}
.btn.letter-spacing {
  letter-spacing: max(0.5vh, 4.5px);
}

a {
  color: white;
}

.arrow {
  height: max(min(4vh, 4vw), 12px);
  margin: max(min(4vh, 4vw), 12px);
}
.arrow.mirrored {
  transform: rotate(180deg);
}

.note {
  max-width: 700px;
  word-break: normal;
}

/*for debugging*/
hr {
  border: none;
  height: 16px;
  background-color: #333;
}

.log-container {
  background-color: #202020;
  color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  width: 80%;
  margin: 30px auto;
  padding: 30px;
  text-align: center;
}

.log-container button {
  background-color: #ffc107;
  color: #202020;
  border-radius: 10px;
  padding: 10px 20px;
  margin-top: 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.log-container button:hover {
  transform: scale(1.1);
}

.log-section {
  margin-top: 20px;
}

.log-section p {
  margin-top: 20px;
  font-size: 1.2em;
  word-wrap: break-word;
  word-break: break-all;
}

.log-section h1 {
  word-wrap: break-word;
  word-break: break-all;
}
</style>
