import algosdk from "algosdk";

export function decodeUnsignedTx(string) {
    return algosdk.decodeUnsignedTransaction(
        Buffer.from(string, "base64")
    )
}

export function decodeSignedTx(string) {
    return algosdk.decodeSignedTransaction(
        Buffer.from(string, "base64")
    )
}
